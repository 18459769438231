import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import './App.css';

import axios, { setUpAxiosClientIdHeader, setUpResponseInterceptor } from 'axios.config';
import Logger from 'common/utils/logger';
import { CallbackPage } from 'common/pages/CallbackPage';
import { RedirectPage } from 'common/pages/RedirectPage';
import { UserDataContext } from 'common/context/userContext';
import { BrandDataContext } from 'common/context/brandContext';
import { ErrorBoundary } from 'common/ErrorBoundary';
import { localCache } from 'localCache';
import { LoadingOverlay } from 'common/components/LoadingOverlay/LoadingOverlay';
import { appInsights, reactPlugin } from './AppInsights';
import { useHistory, useLocation } from 'react-router-dom';
import SkyWifi from './brands/skywifi';

const BrandPage = loadable(
  (props: { name: string }) => import(/* webpackChunkName: "[request]" */ `./brands/${props.name}`)
);

let is_idp_flow: boolean | undefined = false;

// Make a call to Lookup API to identify brand
const getBrand = async (clientId: string) => {
  if (is_idp_flow == true) {
    return axios.get(`/lookup_func`, { headers: { client_id: clientId, idp_flow: is_idp_flow } });
  } else {
    return axios.get(`/lookup_func`, { headers: { client_id: clientId } });
  }
};

export const history = createBrowserHistory();

function App(props: any) {
  const [userData, setUserData] = React.useState<any>();
  const [brandData, setBrandData] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(true);
  const [error, setError] = React.useState<Error>();

  const updateUserData = (profileData: any) => {
    if (profileData) {
      let { firstName, lastName, c_origin } = profileData;
      const userData = { firstName, lastName, c_origin };
      setUserData(userData);
    }
  };

  const userDataContext = {
    userData,
    updateUserData,
  };

  // Make loading setter available on global object to be called by i18n
  // @ts-ignore
  window.setLoading = setLoading;

  // Get client_id from query param,
  let currentURL = new URL(window.location.href);
  let flow = window.location.pathname;
  let isIdP = new URLSearchParams(window.location.search).get('is_idp') || '';
  is_idp_flow = flow?.includes('idp-signin') || isIdP == 'true';
  let clientId = new URLSearchParams(window.location.search).get('client_id') || '';
  let email = new URLSearchParams(window.location.search).get('email') || '';


  if (clientId) {
    // store in session storage for reuse
    sessionStorage.setItem('current-client-id', clientId);
  } else {
    // fallback to hostname as value, if client_id not available
    clientId = window.location.origin;
  }

  // sessionStorage.removeItem('email');

  if(email){
    sessionStorage.setItem('email', email);
  }

  React.useEffect(() => {
    const brandData = localCache.brand;

    if (brandData) {
      // cache was found, verify the data is loaded for correct client_id and/or hostname
      if (brandData.value.client_id.includes(clientId) || brandData.value.hostname === clientId) {
        // correct brand data is saved in cache, load that
        setBrandData(brandData.value);
        return;
      } else {
        // incorrect brand data is saved in cache, delete that
        delete localCache.brand;
      }
    }

    // if not found in cache or cache invalid/corrupt and deleted, then load again from n/w call
    fetchBrandData(clientId);
  }, []);

  const fetchBrandData = async (clientId: string) => {
    try {
      const response = await getBrand(clientId);
      console.log('brand_details', response);
      const UI_DATA_CACHING_ALLOWED = response.data.result.brand_metadata?.flags?.UI_DATA_CACHING_ALLOWED;
      if (UI_DATA_CACHING_ALLOWED) {
        // cached on browser for limited time to reduce n/w call when the data will rarely change.
        // this will result in low latency and cost savings.
        const expireIn = response.data.result.brand_metadata?.configs?.CACHING_TIME_IN_MINUTES;
        localCache.brand = { ...response.data.result, expireIn };
        console.debug(`Data cached for ${expireIn} minutes`);
      }
      setBrandData(response.data.result);
    } catch (error: any) {
      // case where API call fails for whatever reason and no brand name is received
      console.debug('Error Occurred:', error.response?.data.response_code);
      let url = new URL(currentURL.href);
      url.searchParams.delete('client_id');
      window.location.replace(url.href);
      setError(error);
    }
  };

  React.useEffect(() => {
    if (brandData) {
      let fallbackClientId = new URLSearchParams(window.location.search).get('client_id') || '';

      if (!fallbackClientId) {
        fallbackClientId = brandData.okta_domain_details.chameleon_client_id;
      }

      // setUpResponseInterceptor(brandData);
      setUpAxiosClientIdHeader(fallbackClientId);

      // Load i18n configuration for this brand
      loadI8nFile(brandData);

      // set logger details
      Logger.setMetaData(brandData.brand_name, 'placeholder'); // TODO: replace with tenant_name
      Logger.setLogLevels(brandData.brand_metadata.log_levels || ['all']);
    }
  }, [brandData]);

  const handleReload = React.useCallback(() => {
    window.location.reload();
  }, []);

  const loadI8nFile = async (brandData: any) => {
    try {
      const setupI18n = (await import(`./brands/${brandData.brand_name}/locales/i18n`)).default;
      const overrides = brandData.brand_metadata.locale_override;

      setupI18n(overrides);
    } catch (error: any) {
      setError(error);
    }
  };

  if (error) {
    // show a error page with retry link
    return (
      <div className="App">
        <div
          className="App-header"
          style={{
            color: 'black',
          }}
        >
          Something went wrong.
          <br />
          {/* <div>
            Please&nbsp;
            <span onClick={handleReload} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              try again
            </span>
            &nbsp;later.
          </div> */}
        </div>
      </div>
    );
  }

  if (loading || !brandData) {
    return (
      <LoadingOverlay active={loading || !brandData} text="Loading...">
        <div className="App App-header" style={{ color: 'black' }}></div>
      </LoadingOverlay>
    );
  } else {
    // if brand name is available, set to context, render the app
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrandDataContext.Provider value={brandData}>
          <UserDataContext.Provider value={userDataContext}>
            <Router history={history}>
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/callback" component={CallbackPage} />
                  <Route exact path="/redirect/:clientId" component={RedirectPage} />
                  <BrandPage name={brandData.brand_name} />
                </Switch>
              </ErrorBoundary>
            </Router>
          </UserDataContext.Provider>
        </BrandDataContext.Provider>
      </AppInsightsContext.Provider>
    );
  }
}

export default App;
